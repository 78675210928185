import { Component, inject, OnInit } from "@angular/core"
import { CheckoutService } from "../checkout/checkout.service"

@Component({
  imports: [],
  standalone: true,
  template: `
    success
  `,
  selector: "e2e-success",
})
export class SuccessComponent implements OnInit {
  private cartService = inject(CheckoutService)

  ngOnInit() {
    this.cartService.emptyCart()
  }

}
