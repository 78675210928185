import { Component, computed, inject, signal } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { UserAvatarComponent } from "./user-avatar.component"
import { RouterLink, RouterLinkActive } from "@angular/router"
import { UiInheritDirective } from "ui/inherit"
import { UiThemeDarkModeSwitcherComponent, UiThemeModeSwitcherComponent, UiThemeService } from "ui/theme"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { GroupsService } from "./products/groups/groups.service"
import { faEclipse, faMoon, faSun } from "@fortawesome/pro-solid-svg-icons"
import { AppService } from "../app.service"
import { CartIconComponent } from "./checkout/cart-icon.component"
import { CartPromptComponent } from "./checkout/cart-prompt.component"

@Component({
  imports: [
    UserAvatarComponent,
    NgOptimizedImage,
    UiInheritDirective,
    RouterLink,
    UiThemeDarkModeSwitcherComponent,
    UiThemeModeSwitcherComponent,
    RouterLinkActive,
    CartIconComponent,
    CartPromptComponent,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="row shadow-lg p-1 px-5 flex-grow">

      <div
        class="cursor-pointer text-2xl"
        routerLink="/"
      >
        Duggan Farrier Supply
      </div>

      <!--
            <a
              style="width: 48px"
              routerLink="/"
            >
              <img
                ngSrc="assets/logo.png"
                height="60"
                width="60"
                alt=""
                priority=""
              />
            </a>
      -->
      <button
        class="btn btn-sm"
        routerLink="/"
        routerLinkActive="btn-primary"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Home
      </button>
      <button
        class="btn btn-sm"
        routerLink="/products"
        routerLinkActive="btn-primary"
        (click)="scrollToProducts()"
      >
        Search Products
      </button>
      <button
        class="btn btn-sm"
        routerLink="/events"
        routerLinkActive="btn-primary"
      >
        Events
      </button>
      <button
        class="btn btn-sm"
        routerLink="/school"
        routerLinkActive="btn-primary"
      >
        School
      </button>

      <div
        class="flex-grow cursor-pointer self-stretch"
        routerLink="/"
      ></div>
      
      <e2e-cart-icon (showCart)="showCart.set(true)"/>
      
      @if (showCart()) {
        <e2e-cart-prompt (close)="showCart.set(false)"/>
      }

      @if (showCallUsButton()) {
        <a
          class="btn btn-sm"
          href="tel:17634275850"
        >
          Call us: 763-427-5850
        </a>
      }

      @if (!loggedIn()) {
        <button
          class="btn btn-sm"
          routerLink="/login"
          (click)="scrollToTop()"
        >
          Create Account
        </button>
        <!--
                <button
                  class="btn btn-sm"
                  routerLink="/login"
                  (click)="scrollToTop()"
                >
                  Sign In
                </button>
        -->
      }
      <!--
            @if (loggedIn()) {
              <button
                class="btn"
                routerLink="/login"
                (click)="scrollToTop()"
              >
                Sign Out
              </button>
            }
      -->

      <e2e-user-avatar buttonClasses="btn-sm"/>

      <div class="row">
        <lib-ui-theme-mode-switcher buttonClasses="btn-sm"/>
        <!--        <lib-ui-theme-dark-mode-switcher darkModeTheme="duggan_dark"/>-->
      </div>
    </div>
  `,
  selector: "e2e-site-top-bar",
})
export class SiteTopBarComponent {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)
  private groupService = inject(GroupsService)
  private themeService = inject(UiThemeService)
  private appService = inject(AppService)

  loggedIn = computed(() => !!this.authDataAccessService.afUser())

  showCallUsButton = computed(() => !this.appService.callUsElementIsInViewport())

  showCart = signal(false)

  constructor() {
    this.themeService.setThemeNames(["auto", "duggan_light", "duggan_dark"])
    this.themeService.setThemeIcons([faEclipse, faSun, faMoon])
  }

  scrollToProducts() {
    this.groupService.setScrollToProducts()
  }

  scrollToTop() {
    // window.scrollTo({ behavior: "smooth", top: 0 })
    this.groupService.setScrollToTop()
  }

}
